html,
body {
  /* min-height: calc(100vh - 46px); */
  /* overflow: auto !important;
  padding-right: 0 !important; */
}

body {
  background-color: #f2f2f2;
  margin: 0;
  font-family: "Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: black;
}

button:hover {
  border: 1px solid white;
}

#root {
  height: calc(100vh - 95px);
  overflow: auto;
  margin-top: 65px;
};